import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  class: "card mb-5 mb-xl-10",
  id: "kt_profile_details_view"
}
const _hoisted_3 = { class: "card-header" }
const _hoisted_4 = { class: "card-title" }
const _hoisted_5 = { class: "fw-bolder" }
const _hoisted_6 = { class: "card-toolbar" }
const _hoisted_7 = {
  class: "d-flex justify-content-end",
  "data-kt-subscription-table-toolbar": "base"
}
const _hoisted_8 = { class: "card-body p-9" }
const _hoisted_9 = { class: "mb-10" }
const _hoisted_10 = { class: "mb-4" }
const _hoisted_11 = { class: "d-flex flex-wrap py-5" }
const _hoisted_12 = { class: "flex-equal me-5" }
const _hoisted_13 = { class: "table fs-6 fw-bold gs-0 gy-2 gx-2 m-0" }
const _hoisted_14 = { class: "text-gray-400 min-w-175px w-175px" }
const _hoisted_15 = { class: "text-gray-800 min-w-200px" }
const _hoisted_16 = { class: "text-gray-400" }
const _hoisted_17 = { class: "text-gray-800" }
const _hoisted_18 = ["href"]
const _hoisted_19 = { class: "flex-equal me-5" }
const _hoisted_20 = { class: "table fs-6 fw-bold gs-0 gy-2 gx-2 m-0" }
const _hoisted_21 = { class: "text-gray-400" }
const _hoisted_22 = { class: "text-gray-800" }
const _hoisted_23 = { class: "text-gray-400 min-w-175px w-175px" }
const _hoisted_24 = { class: "text-gray-800 min-w-200px" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.can('read', 'settings'))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.translate('contactUsOverview')), 1)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_router_link, {
                  to: "/manage/setting/contact-us-listing",
                  class: "btn btn-light me-3"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate('backToList')), 1)
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("h5", _hoisted_10, _toDisplayString(_ctx.translate('basicInfo')) + ":", 1),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("table", _hoisted_13, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_14, _toDisplayString(_ctx.translate('fullName')) + ": ", 1),
                      _createElementVNode("td", _hoisted_15, _toDisplayString(_ctx.contactUs.name), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_16, _toDisplayString(_ctx.translate('email')) + ":", 1),
                      _createElementVNode("td", _hoisted_17, [
                        _createElementVNode("a", {
                          href: `mailto:${_ctx.contactUs.email}`,
                          class: "text-gray-800 text-hover-primary px-0"
                        }, _toDisplayString(_ctx.contactUs.email), 9, _hoisted_18)
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("table", _hoisted_20, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_21, _toDisplayString(_ctx.translate('phone')) + ":", 1),
                      _createElementVNode("td", _hoisted_22, _toDisplayString(_ctx.contactUs.phone), 1)
                    ]),
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_23, _toDisplayString(_ctx.translate('message')) + ": ", 1),
                      _createElementVNode("td", _hoisted_24, _toDisplayString(_ctx.contactUs.message), 1)
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}